import { Typography, styled } from '@mui/material'

export const OuterWrapper = styled('div')(({ theme }) => ({
  width: '220px',
  [theme.breakpoints.down('sm')]: {
    width: '150px',
  },
}))

export const TileWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const ProductTitle = styled('div')(({ theme }) => ({
  marginTop: '10px',
  marginBottom: '6px',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '19px',
  height: '2.5em',
  color: '#003566',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: '2',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    height: '2.6em',
  },
}))

export const ImgWrapper = styled('div')(({ theme }) => ({
  height: '150px',
  width: '220px',
  [theme.breakpoints.down('sm')]: {
    height: '100px',
    width: '150px',
  },
}))

export const ImgStyle = styled('img')(({ theme, isImagePadding }) => ({
  objectFit: 'contain',
  color: 'transparent',
  backgroundColor: isImagePadding ? 'rgb(235, 235, 235)' : 'inherit',
  height: `calc(100% - ${isImagePadding ? '40px' : '0px'})`,
  width: `calc(100% - ${isImagePadding ? '40px' : '0px'})`,
  padding: isImagePadding ? '20px' : 0,
  [theme.breakpoints.down('sm')]: {
    height: `calc(100% - ${isImagePadding ? '28px' : '0px'})`,
    width: `calc(100% - ${isImagePadding ? '28px' : '0px'})`,
    padding: isImagePadding ? '14px' : 0,
  },
}))

export const PriceWrapper = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isSale',
})(({ isSale }) => ({
  fontSize: '23px',
  color: isSale ? '#e11f21' : '#003566',
}))

export const SalesFlagWrapper = styled('div')(({ theme }) => ({
  '& .MuiBox-root': {
    left: '5px',
    top: '5px',
    [theme.breakpoints.down('sm')]: {
      left: '2px',
      top: '2px',
      borderRadius: '4px',
      padding: '4px 8px',
      '& .MuiTypography-root': {
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
  },
}))
