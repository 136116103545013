import React from 'react'
import { Skeleton } from '@mui/material'
import { string, bool, number, object } from 'prop-types'
import StrapiLink from '@templates/strapi-cms/content-types/Link'
import { currencyFormatUS } from '@helpers/string-helper'
import { productUrl } from '@helpers/route'
import PriceAtom from '../../../@rtg2022/components/atoms/Price/Price'
import SaleFlag from '../../../@rtg2022/components/atoms/SaleFlag/SaleFlag'
import {
  OuterWrapper,
  ImgWrapper,
  ImgStyle,
  ProductTitle,
  TileWrapper,
  PriceWrapper,
  SalesFlagWrapper,
} from './ProductTileMini.styles'

const ProductTileMini = ({
  hideTitleAndPrice = false,
  isSkeleton,
  trackingData,
  productImage,
  isSale,
  price,
  title,
  sku,
  route,
  isError,
  saleText,
  isThankyouDiscount,
}) => {
  if (isError) return <div>Error {JSON.stringify(isError)}</div>
  const isImagePadding = !(productImage && productImage.search(/image-room|image-3-2/) > -1)
  return (
    <OuterWrapper>
      {isSkeleton ? (
        <>
          <ImgWrapper>
            <Skeleton variant="rectangular" width="100%" height="100%" />
          </ImgWrapper>
          {!hideTitleAndPrice && (
            <>
              <ProductTitle>
                <Skeleton width="100%" />
                <Skeleton width="100%" />
              </ProductTitle>
              <PriceWrapper>
                <Skeleton width="50%" />
              </PriceWrapper>
            </>
          )}
        </>
      ) : (
        <StrapiLink
          data={{
            InternalUrl: productUrl(route),
            testId: `recently-viewed-product-tile-link:${sku}`,
          }}
          trackingData={trackingData}
          aria-hidden="true"
          tabIndex="-1"
          disableUnderline
        >
          <TileWrapper>
            {isSale && (
              <SalesFlagWrapper>
                <SaleFlag label={saleText} />
              </SalesFlagWrapper>
            )}
            <ImgWrapper>
              <ImgStyle src={`${productImage}&h=300`} alt={title} isImagePadding={isImagePadding} />
            </ImgWrapper>
            {!hideTitleAndPrice && (
              <>
                <ProductTitle>{title}</ProductTitle>
                <PriceWrapper component="p" variant="body2" isSale={isSale || isThankyouDiscount}>
                  <PriceAtom price={currencyFormatUS(price)} />
                </PriceWrapper>
              </>
            )}
          </TileWrapper>
        </StrapiLink>
      )}
    </OuterWrapper>
  )
}

export default React.memo(ProductTileMini)

ProductTileMini.propTypes = {
  hideTitleAndPrice: bool,
  isSkeleton: bool,
  trackingData: object,
  productImage: string,
  isSale: bool,
  price: number,
  title: string,
  sku: string,
  route: string,
  isError: bool,
  saleText: string,
  isThankyouDiscount: bool,
}
