import React from 'react'
import { objectOf, any, string, bool } from 'prop-types'
import { styled, Box, Typography } from '@mui/material'
import { decodeHtml } from '@helpers/string-helper'
import { productUrl } from '@helpers/route'
import { getProductDisplayImage } from '@helpers/product'
import { useTest, TEST_ID_CATEGORIES } from '@hooks/useTest'
import RTGLink from '../../../shared/link'
import PriceAtom from '../../../../@rtg2022/components/atoms/Price/Price'

const DeliveryProductWrapper = styled(RTGLink)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  color: theme.palette.secondary.dark,
  marginBottom: '5px',
  img: {
    width: '100px',
    height: '75px',
    backgroundColor: theme.palette.secondary.light,
    objectFit: 'contain',
  },
  span: {
    fontWeight: '500',
    fontSize: '16px',
  },
}))

const ProductOrderInfo = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
}))

const ProductOrderDescription = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 8px',
  flex: 1,
  '& > div': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '1rem',
  },
  p: {
    fontSize: '0.75rem',
  },
}))

const WarrantyText = styled(Typography)(() => ({
  fontSize: '0.75rem',
  marginTop: '0.5rem',
}))

const DeliveryProduct = ({
  children,
  className,
  item,
  noImage,
  requiredAddon,
  warrantyEnabled,
  isStoreSku,
  supressQuantity,
  displayPrice = true,
}) => {
  const { price, quantity, lineItem } = item || {}
  const product = item?.product || item
  const route = product && product?.route ? product?.route : null
  const { generateTestId } = useTest()

  const imageUrl = getProductDisplayImage(product)
  const needImageHeight =
    product?.sku !== '83333333' && !item?.isConfigurable && !imageUrl?.endsWith('image-not-found.png')

  return (
    <DeliveryProductWrapper
      data-testid={generateTestId(TEST_ID_CATEGORIES.DELIVERY_PRODUCT, product?.sku)}
      className={className}
      data={{
        slug: productUrl(route),
        altDesc: product?.title ? product?.title : '',
      }}
      disabled={productUrl(route).includes('productnull') || requiredAddon || isStoreSku}
    >
      <ProductOrderInfo>
        {!noImage && (
          <>
            {!requiredAddon && product?.primary_image && (
              <Box
                data={{
                  slug: productUrl(route),
                  altDesc: product?.title ? product?.title : '',
                }}
                aria-hidden="true"
                tabIndex="-1"
              >
                <img
                  src={`${imageUrl}${needImageHeight ? '&h=75' : ''}`}
                  alt=""
                  role="presentation"
                  aria-hidden="true"
                  className="product-image"
                  style={{ backgroundColor: 'unset' }}
                />
              </Box>
            )}
          </>
        )}
        <ProductOrderDescription>
          <Box>
            <Box>
              {product?.title ? decodeHtml(product?.title) : `SKU: ${product?.sku}`}{' '}
              {!supressQuantity && <Typography className="quantity">Qt: {` ${quantity}`}</Typography>}
            </Box>
            {displayPrice && (price || lineItem?.unitPrice) && <PriceAtom price={price || lineItem?.unitPrice} />}
          </Box>
          {warrantyEnabled && (
            <Box>
              <WarrantyText>Furniture Protection Plan Added</WarrantyText>
              {displayPrice && lineItem?.warrantyPrice?.price > 0 && (
                <PriceAtom price={lineItem?.warrantyPrice?.price} />
              )}
            </Box>
          )}
          {children}
        </ProductOrderDescription>
      </ProductOrderInfo>
    </DeliveryProductWrapper>
  )
}

DeliveryProduct.propTypes = {
  children: any,
  className: string,
  item: objectOf(any),
  requiredAddon: bool,
  warrantyEnabled: bool,
  noImage: bool,
  isStoreSku: bool,
  displayPrice: bool,
  supressQuantity: bool,
}

export default DeliveryProduct
