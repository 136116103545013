import React from 'react'
import { string } from 'prop-types'
import { Container, Box, Typography, styled } from '@mui/material'
import QuestionMarkOutlined from '@mui/icons-material/QuestionMarkOutlined'
import PhoneOutlined from '@mui/icons-material/PhoneOutlined'
import MailOutlined from '@mui/icons-material/MailOutlined'
import RTGLink from './link'
import '../../assets/css/components/shared/condensed-contact-links.sass'

const CondensedContactContainer = styled(Container)(({ theme }) => ({
  p: {
    fontSize: '19px',
    fontStyle: 'normal',
    fontWeight: '400',
    color: theme.palette.secondary.dark,
  },
}))

const LinksContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  marginTop: '21px',
  a: {
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 400,
    svg: {
      width: '35px',
      height: '35px',
    },
  },
}))

const CondensedContactLinks = ({ className }) => (
  <CondensedContactContainer className={className}>
    <Typography>Contact Us</Typography>
    <LinksContainer>
      <RTGLink
        data={{
          slug: '/customer-service/common-questions',
          category: 'cart/checkout',
          action: 'FAQ click',
          label: 'FAQ',
        }}
        className="grid-y"
      >
        <QuestionMarkOutlined />
        FAQ
      </RTGLink>
      <RTGLink
        data={{
          url: 'tel:18887095380',
          category: 'cart/checkout',
          action: 'Call click',
          label: 'Call',
        }}
        className="grid-y"
      >
        <PhoneOutlined />
        Phone
      </RTGLink>

      <RTGLink
        data={{
          url: 'mailto:internetSalesSupport@roomstogo.com',
          category: 'cart/checkout',
          action: 'Email click',
          label: 'Email',
        }}
        className="grid-y"
      >
        <MailOutlined />
        Email
      </RTGLink>
    </LinksContainer>
  </CondensedContactContainer>
)

CondensedContactLinks.propTypes = {
  className: string,
}

export default CondensedContactLinks
