import React from 'react'
import PropTypes from 'prop-types'
import { Box, styled } from '@mui/material'
import { months, weekdays } from '@helpers/string-helper'
import { getDeliveryMessagingByOrder, shouldShowAdditionalInstructions } from '@helpers/delivery'
import { flatten, uniq } from 'lodash'
import DeliveryProduct from '../checkout/checkout-parts/delivery-section/delivery-product-v2'
import DistributionCenterPopup from '../checkout/checkout-parts/delivery-section/distribution-center-popup/index'
import DeliveryTile from '../../@rtg2022/components/molecules/DeliveryTile/delivery-tile'

const Summary = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  width: '100%',
})

const SummaryGroup = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  textAlign: 'left',
  width: '100%',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
}))

const ProductItem = styled('div')(({ theme }) => ({
  display: 'flex',
  color: theme.palette.secondary.dark,
  justifyContent: 'flex-end',
}))

const DeliveryProductCart = styled(DeliveryProduct)(() => ({
  padding: '15px 0',
  '&:not(:last-child)': {
    borderBottom: `1px solid rgba(0, 83, 160, 0.12)`,
  },
}))

const DeliveryProductSubItem = styled(DeliveryProduct)(() => ({
  padding: '0!important',
  marginBottom: '4px!important',
  '&:first-of-type': {
    marginTop: '8px!important',
  },
  '.MuiBox-root': {
    padding: '0!important',
    fontSize: '0.75rem',
  },
}))

const ProductItemDescription = styled('p')`
  font-size: 12px;
`

// const ProductItemQuantity = styled('p')`
//   min-width: 36px;
//   font-size: 12px;
//   margin-left: 20px;
// `

const SpecialInstructionsWrapper = styled('div')`
  padding: 0.5em 0;
  .provided-instructions {
    display: inline;
    margin-right: 0.4em;
    &.bold {
      font-weight: 600;
    }
  }
`

const formatDate = dateString => {
  const date = new Date(dateString)
  return `${weekdays?.[date.getUTCDay()]}, ${months[date.getUTCMonth()]} ${date
    .getUTCDate()
    .toString()
    .padStart(2, '0')}, ${date.getUTCFullYear()}`
}

const SplitDeliverySummaryV2 = ({ order }) => {
  const shipmentItems = order?.aggCartItems?.map(cItem => {
    if (!cItem?.isConfigurable) {
      return cItem
    }
    // need to get Mod1 configured item's childItems from order.lineItems:
    const subItems = order?.lineItems?.find(x => x.sku === cItem.product.sku)?.childItems
    return { ...cItem, sku: cItem.product.sku, subItems }
  })
  const shipmentsData = order?.splitCalendar?.map((shipment, index) => {
    const dDate = order?.splitDeliveryDates?.[index]?.deliveryDate
    return {
      deliveryDate: dDate,
      deliveryDateObject: new Date(dDate),
      shipmentSkus: shipment.skus,
      title: formatDate(dDate),
      items: uniq(
        flatten(
          shipment.skus.map(sku =>
            shipmentItems?.filter(
              item =>
                item.product?.sku === sku ||
                Object.values(item?.product?.items_in_room || {})?.[0]
                  ?.map?.(subItem => subItem.sku)
                  .includes(sku) ||
                Object.values(item?.product?.items_in_room || {})?.[0]
                  ?.map?.(subItem => subItem.sku)
                  .includes(order.packageSkuMap?.[sku]) ||
                item.activeAddons?.map?.(activeAddon => activeAddon.sku).includes(sku) ||
                order?.lineItems?.filter?.(lineItem => {
                  if (lineItem.sku === item.sku) {
                    item.subItems = lineItem.childItems
                    return lineItem.childItems.map(childItem => childItem.sku).includes(sku)
                  }

                  return false
                }).length > 0,
            ),
          ),
        ),
      ),
    }
  })

  const shipmentMessaging = order.splitDeliveryDates.map((deliveryDate, index) =>
    getDeliveryMessagingByOrder(
      index === 0 ? deliveryDate?.expressDelivery ?? false : false,
      order.doorwayDelivery,
      order.isPickup,
    ),
  )

  const deliveryCount = shipmentMessaging?.length ?? 0

  const distributionCenter = order.isPickup && <DistributionCenterPopup order={order} />

  const isProductIsComplete = (item, subItems) => {
    const numberOfPieces = Object.values(order?.packageSkuMap ?? {}).filter(value => value === item.sku).length
    return numberOfPieces === subItems.length
  }

  return (
    <Summary>
      <SummaryGroup>
        {shipmentsData.map((shipment, index) => {
          const tileKey = `shipment_${index}`
          return (
            <DeliveryTile
              key={tileKey}
              title={shipment.title}
              subtitle={`Delivery ${index + 1} of ${deliveryCount}`}
              description={deliveryCount > 0 ? shipmentMessaging[index]?.label ?? '' : ''}
              subheaderComponent={distributionCenter}
            >
              {shipment?.items?.map((item, i) => {
                if (!item) return null
                const skuKey = `${item.sku}_item_${i}`
                if (!item.product.items_in_room) {
                  const subItems = item.subItems?.filter(({ sku }) => shipment.shipmentSkus.includes(sku)) || []
                  if (subItems?.length === 0) {
                    if (item.activeAddons) {
                      return item.activeAddons
                        ?.filter(({ sku }) => shipment.shipmentSkus.includes(sku))
                        .map((addon, indx) => {
                          const addonKey = `${addon.sku}_addon_${indx}`
                          return <DeliveryProductSubItem key={addonKey} item={addon} displayPrice={false} />
                        })
                    }

                    return (
                      <DeliveryProductCart
                        key={skuKey}
                        item={item}
                        warrantyEnabled={item.warrantyEnabled}
                        displayPrice={false}
                      />
                    )
                  }

                  const fragmentKey = `item_without_subitems_${i}`
                  return (
                    <React.Fragment key={fragmentKey}>
                      <DeliveryProductCart
                        key={skuKey}
                        item={item}
                        warrantyEnabled={item.warrantyEnabled}
                        displayPrice={false}
                      >
                        {subItems &&
                          !isProductIsComplete(item, subItems) &&
                          subItems?.map((subItem, z) => {
                            const subItemKey = `${subItem.sku}_subItem_${z}`
                            return (
                              <ProductItem key={subItemKey}>
                                <ProductItemDescription>{subItem.title}</ProductItemDescription>
                                {/* <ProductItemQuantity>QTY: {item.quantity * subItem.quantity}</ProductItemQuantity> */}
                              </ProductItem>
                            )
                          })}
                      </DeliveryProductCart>

                      {item.activeAddons
                        ?.filter(({ sku }) => shipment.shipmentSkus.includes(sku))
                        .map((addon, indx) => {
                          const addonKey = `${addon.sku}_addon_${indx}`
                          return (
                            <DeliveryProductSubItem key={addonKey} item={addon} displayPrice={false} supressQuantity />
                          )
                        })}
                    </React.Fragment>
                  )
                }

                const itemsInPackage = item?.product?.items_in_room?.[order?.division]?.filter?.(
                  itemInRoom =>
                    shipment.shipmentSkus.includes(itemInRoom.sku) ||
                    shipment.shipmentSkus
                      .map?.(shipmentSku => {
                        if (order.packageSkuMap?.[shipmentSku] === itemInRoom.sku) {
                          if (!itemInRoom.subItems) {
                            itemInRoom.subItems = []
                          }

                          itemInRoom.subItems.push(shipmentSku)
                          itemInRoom.subItems = uniq(itemInRoom.subItems)
                        }

                        return shipmentSku
                      })
                      .some?.(shipmentSku => order.packageSkuMap?.[shipmentSku] === itemInRoom.sku),
                )

                if (itemsInPackage?.length === 0) {
                  if (item.activeAddons) {
                    return item.activeAddons
                      ?.filter(({ sku }) => shipment.shipmentSkus.includes(sku))
                      .map((addon, indx) => {
                        const addonKey = `${addon.sku}_addon_${indx}`
                        return (
                          <DeliveryProductSubItem key={addonKey} item={addon} displayPrice={false} supressQuantity />
                        )
                      })
                  }
                  return null
                }

                return (
                  <React.Fragment key={skuKey}>
                    <DeliveryProductCart key={skuKey} item={item} displayPrice={false}>
                      {itemsInPackage?.map((subItem, z) => {
                        const subItemKey = `${subItem.sku}_subItem_${z}`
                        return <DeliveryProductSubItem key={subItemKey} item={subItem} supressQuantity />
                      })}
                    </DeliveryProductCart>
                    {item.activeAddons
                      ?.filter(({ sku }) => shipment.shipmentSkus.includes(sku))
                      .map((addon, indx) => {
                        const addonKey = `${addon.sku}_addon_${indx}`
                        return (
                          <DeliveryProductSubItem key={addonKey} item={addon} displayPrice={false} supressQuantity />
                        )
                      })}
                  </React.Fragment>
                )
              })}
            </DeliveryTile>
          )
        })}
        {order && order.additionalDirections && shouldShowAdditionalInstructions(order) && (
          <SpecialInstructionsWrapper>
            <p className="provided-instructions bold">Provided Instructions:</p>
            <p className="provided-instructions">{order.additionalDirections}</p>
          </SpecialInstructionsWrapper>
        )}
      </SummaryGroup>
    </Summary>
  )
}

SplitDeliverySummaryV2.propTypes = {
  cart: PropTypes.shape({
    cartItems: PropTypes.any,
  }),
  order: PropTypes.object,
}

export default SplitDeliverySummaryV2
